import React, { useEffect, useState } from 'react'
import { CardProductos } from "../cards";
import { ProductModal } from "../productModal";
import AOS from "aos"
import 'aos/dist/aos.css'

function Temperatura() {

    useEffect(() => {
        AOS.init()
    }, []) 

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)

    const handleOpenModal = (producto) => {
        setIsModalVisible(true)
        setSelectedProduct(producto)
    }

    const handleCloseModal = () => {
        setIsModalVisible(false)
        setSelectedProduct(null);
    }

    const data = [
        {
            id: 8,
            nombre: "Sensor tipo k termopar",
            foto: "../assets/Aparatos/Temperatura/sensor-tipo-k-termopar.jpg"
        },
        {
            id: 9,
            nombre: "Soporte Generico para Termometro",
            foto: "../assets/Aparatos/Temperatura/Soporte-Generico-para-Termometro.jpg"
        },
        {
            id: 10,
            nombre: "Termometro digital tm902c",
            foto: "../assets/Aparatos/Temperatura/Termometro-digital-tm902c.jpg"
        },
        {
            id: 11,
            nombre: "Terohigrometro Logtag Uhado 16",
            foto: "../assets/Aparatos/Temperatura/Terohigrometro-Logtag-Uhado-16.jpg"
        }
    ]

    const productos = data.map(producto => {
        return (
            <>
                <CardProductos key={producto.id} producto={producto} onOpen={handleOpenModal} />
            </>
        )
    })

    return (
        <>
            <div class="container w-[95%] mx-auto p-4 flex justify-center my-4">
                <div data-aos="fade-up-left" class="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {productos}
                </div>
                {selectedProduct && (
                    <ProductModal isVisible={isModalVisible} onClose={handleCloseModal} producto={selectedProduct} />
                )}
            </div>
        </>
    )
}

export default Temperatura