function ErrorPage () {
    return (
        <>
            <main class='py-28'>
                <div class='container w-[100%] h-[50vh] grid place-items-center'>
                    <h1 class='text-center text-4xl'>Error 404: Page not found</h1>
                    <img src="../assets/error.gif" class='w-[330px]'></img>
                    <a href="/" class="bg-orange-color text-white text-center font-bold py-2 px-4 w-64 md:w-72 rounded botones-hover my-10">Volver</a>
                </div>
            </main>
        </>
    )
}

export default ErrorPage