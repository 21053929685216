function Footer() {

    // modificacion del footer a un diseño mas profesional y atractivo

    return (
        <>
            <footer class="bg-blue-color py-10 mt-4 h-auto place-items-center">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center mx-10 gap-6">
                    <div class="p-3">
                        <h3 class="text-white text-sm">Redes Sociales</h3>
                        {/* links de redes sociales vacios en espera de ser modificados */}
                        <ul class="text-white text-4xl my-3">
                            <li class="my-2 hover:text-blue-200"><a href="https://www.facebook.com/profile.php?id=61564954795879" target="_blank"><i class='bx bxl-facebook-circle'></i></a></li>
                        </ul>
                    </div>
                    <div class="p-3">
                        <h3 class="text-white text-sm">Contenido</h3>
                        {/* menu de navegacion en la parte de abajo */}
                        <ul class="text-white text-xs my-3">
                            <li class="my-2 hover:text-blue-200"><a href="/" target="_blank">Inicio</a></li>
                            <li class="my-2 hover:text-blue-200"><a href="/nos" target="_self">Nosotros</a></li>
                            <li class="my-2 hover:text-blue-200"><a href="/prods" target="_self">Productos</a></li>
                            <li class="my-2 hover:text-blue-200"><a href="/provs" target="_self">Proveedores</a></li>
                            <li class="my-2 hover:text-blue-200"><a href="/cont" target="_self">Contacto</a></li>
                        </ul>
                    </div>
                    <div class="p-3">
                        <h3 class="text-white text-sm">Contáctanos</h3>
                        <ul class="text-white text-xs my-3">
                            <li class="my-2">Tel. 4493067879</li>
                            <li class="my-2">ventas.metrotools@gmail.com</li>
                        </ul>
                    </div>
                    <div class="p-3">
                        <h3 class="text-white text-sm">Déjanos tu WhatsApp:</h3>
                        {/* formulario redirigido al correo oficial */}
                        <form action="https://formsubmit.co/ventas.metrotools@gmail.com" method="post" enctype="multipart/form-data">
                            <label for="phone"></label>
                            <input type="text" name="phone" placeholder="Teléfono" class="border-b rounded-md p-2 my-3 flex-grow focus-input bg-gray-200 text-xs mr-10" />
                            <input type="submit" value="Enviar" class="py-2 px-10 text-white my-2 text-xs rounded-lg buttons buttons-1 bg-orange-color transition-all duration-200 hover:bg-gray-200 hover:text-blue-color hover:cursor-pointer" />
                        </form>
                    </div>
                </div>
                <div class="container mx-auto my-4 text-center text-sm transition-all duration-200">
                    <p class="text-white">&copy; 2024 Copyright © 2024 | METROTOOLS</p>
                </div>
            </footer >
        </>
    )
}

export default Footer