import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Main,Nosotros,Productos,Proveedores,Contacto,ErrorPage} from "./layouts";
import {Navbar,Footer} from "./components"
// imports de la pagina con las librerias de React-Router-Dom y de componentes del path

function App() {
  return (
    <>
    {/* uso del componente predefinido del navbar */}
      <Navbar/> 
      <BrowserRouter>
      {/* modificacion de la pagina a partir de la asignacion dinamica de elementos */}
        <Routes>
          <Route path="/" element={<Main/>}></Route>
          <Route path="/nos" element={<Nosotros/>}></Route>
          <Route path="/prods" element={<Productos/>}></Route>
          <Route path="/provs" element={<Proveedores/>}></Route>
          <Route path="/cont" element={<Contacto/>}></Route>
          <Route path="*" element={<ErrorPage/>}></Route>
        </Routes>
      </BrowserRouter>
      {/* uso del componente predefinido del footer */}
      <Footer/>
    </>
  );
}

// exportacion de la pagina como tal, para ser renderizada por React JS
export default App;