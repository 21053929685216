import { CardProveedor } from "../components/cards"
import React, { useEffect } from 'react'
import AOS from "aos"
import 'aos/dist/aos.css'

function Proveedores() {

    useEffect(() => {
        AOS.init()
    }, [])

    const data = [
        {
            nombre: "Dibatec",
            foto: "../assets/Proveedores/dibatec.jpg"
        },
        {
            nombre: "CGoldenwall",
            foto: "../assets/Proveedores/golden.jpg"
        },
        {
            nombre: "Rhino",
            foto: "../assets/Proveedores/rhino.png"
        },
        {
            nombre: "Mitutoyo",
            foto: "../assets/Proveedores/mitu.png"
        }
    ]

    const proveedores = data.map(proveedor => {
        return (
            <>
                <CardProveedor proveedor={proveedor} />
            </>
        )
    })

    return (
        <>
            <main class="pt-20 overflow-x-hidden">
                <section class="xl:w-[100%] w-[100%] h-40 bg-gray-200 bg-nosotros-bg flex justify-center items-center md:justify-start">
                    <h1 class="p-4">Nuestros Principales Proveedores</h1>
                </section>
                <h2 class="p-4 text-center font-bold">Proveedores</h2>
                <section class="bg-productos-bg ">
                    <div class="container w-[95%] mx-auto p-4">
                        <div data-aos="fade-up-left" class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-6">
                            {proveedores}
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Proveedores