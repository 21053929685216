import React from "react"

// este componente recibe dos children, el metodo de onOpen para abrir el modal y la informacion
function CardProductos({ producto, onOpen }) {

    return (
        <>
            <div className="card bg-white shadow-lg rounded-lg overflow-hidden flex flex-col w-[300px]">
                <img src={producto.foto} alt="Imagen" className="w-full h-52 object-cover rounded-t-lg" />
                <div className="flex-grow grid place-items-center p-4">
                    {/* aqui se lee la informacion que le mandamos */}
                    <h3 className="text-xl text-center font-semibold">{producto.nombre}</h3>
                </div>
                 <div className="flex justify-center items-center p-4">
                    {/* este es el boton que nos deja abrir el modal */}
                    <button onClick={() => onOpen(producto)} className="bg-orange-color text-white rounded p-2 px-10 botones-hover">
                        Ver
                    </button>
                </div>
            </div>

        </>
    )
}

export default CardProductos