import React, { useEffect } from 'react'
import AOS from "aos"
import 'aos/dist/aos.css'

function Nosotros() {

    useEffect(() => {
        AOS.init()
    }, [])

    return (
        <>

            <main class="pt-20 overflow-x-hidden">
                <section class="w-full h-40 bg-gray-300 flex justify-center items-center md:justify-start bg-productos-bg">
                    <h1 class="p-4 text-3xl font-bold text-blue-color">Sobre Nosotros</h1>
                </section>

                <section class="mt-4 w-full grid place-items-center bg-nosotros-bg py-8 px-4 md:px-0">
                    <h2 data-aos="zoom-in-up" class="text-center text-3xl font-bold mb-4">¿Quiénes somos?</h2>
                    <p data-aos="zoom-in-up" class="p-4 text-justify lg:w-4/5 text-gray-700 leading-relaxed">
                        En MetroTools somos expertos en proporcionar soluciones precisas y confiables para la medición.
                        Nos especializamos en la venta de equipos de medición de alta calidad que cumplen con los estándares más exigentes.
                    </p>

                    <section data-aos="fade-up-left" class="flex flex-col my-6 mx-10 gap-6 place-items-center lg:w-4/5">
                        <div class="flex flex-col md:flex-row justify-around place-items-center w-full gap-6">
                            <div class="text-center md:pr-4 md:w-1/2 bg-white p-6 rounded-lg shadow-lg">
                                <h2 class="text-2xl font-bold mb-4">Misión</h2>
                                <p class="py-2 text-justify text-gray-600">
                                    Proporcionar equipos de medición de alta precisión y calidad que permitan a nuestros clientes optimizar sus procesos y asegurar resultados fiables. Nos comprometemos a ofrecer soluciones innovadoras y un servicio al cliente excepcional, apoyando al éxito y la mejora continua en cada sector que atendemos.
                                </p>
                            </div>

                            <div class="text-center md:pl-4 md:w-1/2 bg-white p-6 rounded-lg shadow-lg">
                                <h2 class="text-2xl font-bold mb-4">Visión</h2>
                                <p class="py-2 text-justify text-gray-600">
                                    Ser el líder global en soluciones de medición, reconocidos por nuestra innovación, precisión y capacidad para superar las expectativas de nuestros clientes. Aspiramos a transformar la forma en que las industrias utilizan la tecnología de medición, contribuyendo al avance tecnológico y al desarrollo sostenible en todo el mundo.
                                </p>
                            </div>
                        </div>
                        <img class="w-60 lg:w-72 rounded-xl shadow-lg" src="../assets/Aparatos/Densidad/Densiometro-C-Goldenwall-DA-300MM.png" alt="Imagen Nosotros" />
                    </section>
                </section>
            </main>

            {/* <main class="pt-20">
                <section class="w-[100%] h-40 bg-gray-300 flex justify-center items-center md:justify-start bg-productos-bg">
                    <h1 class="p-4">Sobre Nosotros</h1>
                </section>

                <section class="mt-4 w-[100%] grid place-items-center bg-nosotros-bg">

                    <h2 data-aos="zoom-in-up" class="text-center">¿Quienes somos?</h2>
                    <p data-aos="zoom-in-up" class="p-4 text-justify lg:w-[80%]">Contamos con un equipo altamente calificado que ofrece servicios acreditados de calibración y mantenimiento preventivo para equipos de medición. Nos aseguramos de que las mediciones de nuestros clientes sean confiables y precisas,
                        garantizando el funcionamiento óptimo de sus instrumentos.
                    </p>

                    <section data-aos="fade-up-left" class="grid grid-cols-1 lg:grid-cols-2 my-6 mx-10 gap-2 place-items-center lg:w-[100%]">
                        <div class="grid grid-cols-1 place-items-center lg:w-[60%]">
                            <h2 class="text-center">Misión</h2>
                            <p class="py-4 text-justify">Nuestra misión es contribuir al equipamiento de los laboratorios en la industria, agregando valor a
                                sus procesos mediante el aseguramiento de la exactitud en sus mediciones.
                            </p>
                            <h2 class="text-center">Visión</h2>
                            <p class="py-4 text-justify">Ser la empresa líder en calibración y mantenimiento preventivo de equipos
                                de medición, brindando
                                servicios de calibración y mantenimiento preventivo a nuestros clientes.
                            </p>
                        </div>
                        <img class="w-[250px] lg:w-[300px] rounded-xl" src="../assets/Aparatos/Densidad/Densiometro-C-Goldenwall-DA-300MM.png" alt="Imagen Nosotros" />
                    </section>

                </section>
            </main> */}
        </>
    )
}

export default Nosotros