import React from 'react';

function Main() {

    const slides = [
        'https://images.alphacoders.com/133/1335375.jpeg',
        'https://images.alphacoders.com/133/1335375.jpeg',
        'https://images.alphacoders.com/133/1335375.jpeg'
    ];

    const whatsapp = 'https://wa.me/4493067879'

    return (
        <>
            <main className="pt-20">

                <section className="w-full secciones flex-col md:flex-row mx-auto">
                    <div
                        className="flex flex-col justify-center mx-10 items-center bg-metro-tools bg-center bg-cover h-72 w-full md:h-[25rem] md:w-2/3 p-4 md:p-8">
                        <h1 className="text-center md:text-5xl font-extrabold text-blue-color mb-3">METROTOOLS</h1>
                        <h2 className="text-2xl md:text-4xl mb-3"><span className="text-orange-500">Medición total</span></h2>
                        <a href={whatsapp} target="_blank" rel="noopener noreferrer" className="botones font-bold bg-orange-500 rounded-2xl w-[230px] md:w-[250px] botones-hover">
                            <box-icon type='logo' size="lg" color="white" name='whatsapp' className="mr-3 font-bold"></box-icon> WhatsApp
                        </a>
                    </div>
                    <div className="carousel hidden md:block w-[90%] md:w-3/4 mt-4 md:mt-0 rounded-lg">
                        <img src="../assets/IMG AI/Reparacion 1.jpg" alt="Reparacion"
                            className="h-72 w-[80%] md:h-96" />
                    </div>
                </section>

                <section className="secciones flex-col-reverse md:flex-row md:p-4 md:relative my-10 mx-10">
                    <div className="flex flex-col justify-center items-center md:relative">
                        <img src="../assets/Aparatos/Densidad/Densiometro-C-Goldenwall-DA-300MM.png" alt="Imagen ¿Quienes somos?" className="h-72 w-[80%] md:h-[350px] rounded-xl" />
                    </div>
                    <div
                        className="flex flex-col justify-center items-center lg:w-[80%] h-72 p-4 md:relative bg-nosotros-bg bg-cover bg-center md:bg-contain">
                        <h2 className="text-center md:text-5xl">Mediciones con Calidad</h2>
                        <p className="mt-4 text-justify">En MetroTools somos expertos en proporcionar soluciones precisas y confiables para la medición.
                            Nos especializamos en la venta de equipos de medición de alta calidad que cumplen con los estándares más exigentes.
                        </p>
                        <div className="mt-8 md:bottom-4">
                            <a href="/nos" className="bg-orange-color text-white font-bold py-2 px-4 w-64 md:w-72 rounded botones-hover">Leer más</a>
                        </div>
                    </div>
                </section>

            </main >
        </>
    );
}

export default Main;
