import React, { useEffect } from 'react'
import AOS from "aos"
import 'aos/dist/aos.css'
// despues de la instalacion de AOS usamos la misma libreria para añador algunas animaciones

function Contacto() {

    useEffect(() => {
        AOS.init()
    }, [])

    // usando UseEffect de React le vamos a dar un valor de inicio a AOS para poder usarlo en toda la pagina

    return (
        <>
            {/* uso de componentes */}
            <main class="pt-20 overflow-x-hidden">
                <section class="w-full h-40 bg-gray-200 bg-circulo-bg bg-no-repeat bg-right flex justify-center items-center md:justify-start">
                    <h1 class="p-4 text-center">Contáctanos</h1>
                </section>

                <section data-aos="fade-up-left" class="bg-nosotros-bg bg-no-repeat w-full overflow-x-hidden">
                    <h2 class="text-center p-4 m-5">Solicita tu cotización</h2>

                    {/* potenciado con la API de formsubmit en linea para hacer esto mas rapido */}
                    <form action="https://formsubmit.co/ventas.metrotools@gmail.com" method="post" enctype="multipart/form-data" class="flex flex-wrap justify-center gap-2 mx-5 pr-10 w-full">
                        <label for="name"></label> {/* label añadido para correccion de despliegue */}
                        <input type="text" name="name" placeholder="Name" class="border-b rounded-lg px-2 py-4 flex-grow basis-40 focus-input bg-gray-200" />
                        <label for="correo"></label>
                        <input type="email" name='correo' placeholder="Email" class="border-b rounded-lg px-2 py-4 flex-grow basis-40 focus-input bg-gray-200" />
                        <label for="mensaje"></label>
                        <textarea name="mensaje" placeholder="Message" class="border rounded-lg px-4 py-6 mt-4 w-full min-w-full max-w-full min-h-[100px] max-h-60 focus-input bg-gray-200"></textarea>
                        <input type="submit" value="Enviar" class="py-4 px-16 text-white mt-4 rounded-lg buttons buttons-1 bg-orange-color transition-all duration-200 hover:bg-blue-color hover:cursor-pointer" />
                    </form>
                </section>

                <section class="py-10 w-full grid place-items-center bg-nosotros-bg bg-no-repeat overflow-y-hidden">
                    <h2 class="p-4 m-5">Nos ubicamos en:</h2>
                    <div class="grid place-items-center w-full">
                        {/* uso del mapa que contiene una animacion de AOS */}
                        <iframe title='Ubicacion' data-aos="fade-up-left" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3703.51497552483!2d-102.30268542464401!3d21.837663280021886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429edb2d624f89f%3A0x79454b96f83f2c61!2sCasa%20Chica%20437%2C%20La%20Casita%2C%2020284%20Aguascalientes%2C%20Ags.!5e0!3m2!1ses-419!2smx!4v1720321288256!5m2!1ses-419!2smx"
                            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="rounded-xl shadow-xl border-b lg:w-[50%] lg:h-[350px] sm:h-[350px] sm:w-[90%] md:h-96 md:w-[80%]">
                        </iframe>
                        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-10">
                            <div data-aos="fade-up-left" class="w-auto bg-orange-color font-bold rounded-xl text-white flex flex-cols-2 gap-2 justify-center items-center my-3 p-3 botones-hover">
                                <i class='bx bx-envelope text-white text-3xl'></i>
                                <p class="text-sm text-white">ventas.metrotools@gmail.com</p>
                            </div>
                            <div data-aos="fade-up-left" class="w-auto bg-orange-color font-bold rounded-xl text-white flex flex-cols-2 gap-2 justify-center items-center my-3 p-3 botones-hover">
                                <i class='bx bx-phone text-white text-3xl'></i>
                                <p class="text-sm text-white">449-306-78-79</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Contacto