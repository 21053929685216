function Navbar() {
    return (
        <>
            <div class="tv:w-[100%] md:w-full w-full mx-auto px-5 overflow-hidden max-w-screen-xl fixed bg-white shadow-sm z-40">
                <header>
                    <nav class="flex h-20 items-center justify-between">

                        <a href="/" class="w-1/2 max-w-[80px] z-40"><img src="../assets/Logos/metrotools-bg.png"
                            alt="Logo Metrotools" /></a>

                        <input type="checkbox" id="menu" class="peer/menu hidden" />
                        <label for="menu"
                            class="w-10 h-10 bg-open-menu bg-cover bg-center cursor-pointer rounded-lg peer-checked/menu:bg-close-menu transition-all z-40 md:hidden"></label>

                        <ul class="fixed inset-0 bg-gray-color px-[5%] grid gap-10 text-center auto-rows-max content-center justify-center clip-circle-0 peer-checked/menu:clip-circle-full transition-[clip-path] duration-500 md:clip-circle-full md:relative md:grid-flow-col md:p-0 md:bg-transparent">
                            {/* cada elemento de esta pagina hace un request al sitio y cambia el contenido */}
                            <li class="enlaces-nav"><a href="/" target="_blank">Inicio</a></li>
                            <li class="enlaces-nav"><a href="/nos" target="_self">Nosotros</a></li>
                            <li class="enlaces-nav"><a href="/prods" target="_self">Productos</a></li>
                            <li class="enlaces-nav"><a href="/provs" target="_self">Proveedores</a></li>
                            <li class="enlaces-nav"><a href="/cont" target="_self">Contacto</a></li>
                        </ul>

                    </nav>
                </header>
            </div>
        </>
    )
}

export default Navbar