import React, { useEffect, useState } from 'react'
import { CardProductos } from "../cards";
import { ProductModal } from "../productModal";
import AOS from "aos"
import 'aos/dist/aos.css'

function Electricidad() {

    useEffect(() => {
        AOS.init()
    }, [])

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)
    // como usamos un modal de esta forma vemos si se muestra o no y ya, 
    // aparte de que ps usamos el producto seleccionado, 
    // todos los componentes de categorias tienen esto

    // metodo que cambia el modal y manda a llamar el producto seleccionado
    const handleOpenModal = (producto) => {
        setIsModalVisible(true)
        setSelectedProduct(producto)
    }

    // lo contrario al anterior
    const handleCloseModal = () => {
        setIsModalVisible(false)
        setSelectedProduct(null);
    }

    // defino las propiedades de mis productos a mostrar aqui
    const data = [
        {
            id: 1,
            nombre: "Amperímetro",
            foto: "../assets/Aparatos/Electricidad/ampe.jpg"
        },
        {
            id: 2,
            nombre: "Frecuencímetro",
            foto: "../assets/Aparatos/Electricidad/FRENCUENCIMETRO.jpg"
        },
        {
            id: 3,
            nombre: "Multímetro",
            foto: "../assets/Aparatos/Electricidad/multime.jpg"
        },
        {
            id: 4,
            nombre: "Óhmetro",
            foto: "../assets/Aparatos/Electricidad/ohmetro.jpg"
        },
        {
            id: 5,
            nombre: "Osciloscopio",
            foto: "../assets/Aparatos/Electricidad/oscilos.webp"
        }
    ]

    // como me gusta el JS, con esto se crean las cartas de los productos
    const productos = data.map(producto => {
        return (
            <>
                <CardProductos key={producto.id} producto={producto} onOpen={handleOpenModal} />
            </>
        )
    })

    return (
        <>
            <div class="container w-[95%] mx-auto p-4 flex justify-center my-4">
                <div data-aos="fade-up-left" class="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {productos}
                    {/* con eso se muestran los productos */}
                </div>
                {selectedProduct && (
                    <ProductModal isVisible={isModalVisible} onClose={handleCloseModal} producto={selectedProduct} />
                )}
                {/* asi de facil se manda a llamar al modal dependiendo del producto seleccionado y 
                    le mandamos los metodos de cerrar y la informacion */}
            </div>
        </>
    )
}

export default Electricidad