import React, { useEffect, useState } from 'react'
import { CardProductos } from "../cards";
import { ProductModal } from "../productModal";
import AOS from "aos"
import 'aos/dist/aos.css'

function Dimensional() {

    useEffect(() => {
        AOS.init()
    }, [])

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)

    const handleOpenModal = (producto) => {
        setIsModalVisible(true)
        setSelectedProduct(producto)
    }

    const handleCloseModal = () => {
        setIsModalVisible(false)
        setSelectedProduct(null);
    }

    const data = [
        {
            id: 1,
            nombre: "Reglas",
            foto: "../assets/Aparatos/Dimensional/Regla-Mitutoyo-150mm.jpg",
            descripcion: "Contamos con reglas de alta calidad en diferentes medidas, las cuales oscilan entre 150 mm y 300 mm. Puedes contactarnos para preguntar por la que más te interese."
        },
        {
            id: 2,
            nombre: "Calibrador (Píe de Rey)",
            foto: "../assets/Aparatos/Dimensional/calibrador.webp"
        },
        {
            id: 1,
            nombre: "Cinta Metrica",
            foto: "../assets/Aparatos/Dimensional/cinta.jpg"
        },
        {
            id: 1,
            nombre: "Micrómetro",
            foto: "../assets/Aparatos/Dimensional/micrometro.jpg"
        },
    ]

    const productos = data.map(producto => {
        return (
            <>
                <CardProductos key={producto.id} producto={producto} onOpen={handleOpenModal} />
            </>
        )
    })

    return ( 
        <>
            <div class="container w-[95%] mx-auto p-4 flex justify-center my-4">
            <div data-aos="fade-up-left" class="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {productos}
                </div>
                {selectedProduct && (
                    <ProductModal isVisible={isModalVisible} onClose={handleCloseModal} producto={selectedProduct} />
                )}
            </div>
        </>
    )
}

export default Dimensional