import React, { useEffect } from 'react'
import AOS from "aos"
import 'aos/dist/aos.css'

/* componente del modal que recibe :
 - informacion
 - estado de visibilidad 
 - metodo de cierre
 */
function ProductModal({ producto, isVisible, onClose }) {
    useEffect(() => {
        AOS.init()
    }, [])
    
    // como buena practica se revisa que en efecto se quiere ver el modal, si no mejor nadota
    if (!isVisible) return null

    return (
        <>
            <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 transition-all p-4">
                <button
                    className="absolute font-bold text-4xl top-2 left-8 text-white"
                    onClick={onClose} 
                >
                    {/* con esto se cierra */}
                    &times;
                </button>
                <div data-aos="fade-up-left" class="bg-white bg-productos-bg rounded-xl grid place-items-center 
                                                    lg:w-[450px] md:w-[390px] sm:w-[300px] h-auto">
                    <div class=" p-4 grid place-items-center">
                        {/* se consume la info del producto */}
                        <h3 class="pb-2">{producto.nombre}</h3>
                        <img src={producto.foto} class="rounded-lg w-[70%] my-2" alt="una foto"/>
                        <p class="text-sm text-justify p-4">{producto.descripcion}</p>
                        <button class="bg-orange-color text-white rounded p-2 hover:bg-blue-color transition-all duration-200 mt-3" 
                                onClick={onClose}> {/* con esto tambien se cierra */}
                            Salir
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductModal