import React, { useEffect, useState } from 'react'
import { CardProductos } from "../cards";
import { ProductModal } from "../productModal";
import AOS from "aos"
import 'aos/dist/aos.css'

function Volumen() {

    useEffect(() => {
        AOS.init()
    }, [])

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)

    const handleOpenModal = (producto) => {
        setIsModalVisible(true)
        setSelectedProduct(producto)
    }

    const handleCloseModal = () => {
        setIsModalVisible(false)
        setSelectedProduct(null);
    }

    const data = [
        {
            id: 12,
            nombre: "Cabezal de goma y gotero de vidrio",
            foto: "../assets/Aparatos/Volumen/cabezal-de-goma-y-gotero-de-vidrio.jpg"
        },
        {
            id: 13,
            nombre: "Juego de Crisoles de Cermica",
            foto: "../assets/Aparatos/Volumen/Juego-de-Crisoles-de-Cermica.jpg"
        },
        {
            id: 14,
            nombre: "Vaso Precipitado 250ml",
            foto: "../assets/Aparatos/Volumen/Vaso-Precipitado-250ml.jpg"
        },
        {
            id: 15,
            nombre: "Vaso Precipitado 500ml",
            foto: "../assets/Aparatos/Volumen/Vaso-Precipitado-500ml.jpg"
        }
    ]

    const productos = data.map(producto => {
        return (
            <>
                <CardProductos key={producto.id} producto={producto} onOpen={handleOpenModal} />
            </>
        )
    })

    return (
        <>
            <div class="container w-[95%] mx-auto p-4 flex justify-center my-4">
                <div data-aos="fade-up-left" class="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {productos}
                </div>
                {selectedProduct && (
                    <ProductModal isVisible={isModalVisible} onClose={handleCloseModal} producto={selectedProduct} />
                )}
            </div>
        </>
    )
}

export default Volumen