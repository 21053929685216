// importacion de los componentes de las categorias aca bien profesional y librerias a usar
import { Ambiente, Densidad, Dimensional, Electricidad, Luz, Masa, Movimiento, Presion, Temperatura, Tiempo, Volumen } from "../components/categories"
import React, { useState } from 'react'
import 'aos/dist/aos.css'

function Productos() {

    // asignacion de los componentes a un diccionario de React
    const categories = {
        Condiciones: <Ambiente/>,
        Densidad: <Densidad />,
        Dimensional: <Dimensional />,
        Electricidad: <Electricidad />,
        Masa: <Masa />,
        Movimiento: <Movimiento />,
        Óptica: <Luz />,
        Presión: <Presion />,
        Temperatura: <Temperatura />,
        Tiempo: <Tiempo />,
        Volumen: <Volumen />
    }

    // uso de UseState para modificar el contenido de forma dinamica
    const [currentComponent, setCurrentComponent] = useState('Condiciones')

    return (
        <>

            <main class="pt-20 overflow-x-hidden">

                <section class="w-[100%] h-40 bg-gray-200 flex justify-center items-center md:justify-start bg-circulo-bg bg-no-repeat bg-right">
                    <h1 class="p-4">Catálogo</h1>
                </section>

                <h2 class="text-center mt-6">Descubre nuestros productos</h2>

                <div class="container w-[95%] mx-auto p-4 bg-nosotros-bg">
                    <div className="my-4 w-full flex flex-wrap lg:flex-nowrap lg:flex-row md:flex-wrap md:flex-row gap-4 justify-center">
                        {/* dependiendo del boton seleccionado se cambia el estado de la pagina para llamar al componente que le corresponde
                            
                            ademas se crea un boton por cada componente */}
                        {Object.keys(categories).map((category) => (
                            <button
                                key={category}
                                onClick={() => setCurrentComponent(category)}
                                className={`text-blue-color text-xs hover:border-b border-orange-color transition-all ${currentComponent === category ? 'border-b' : 'hover:border-gray-500'} px-4 py-2`}
                            >
                                {category === 'Condiciones' ? 'Condiciones Ambientales' : category}
                            </button>
                        ))}
                    </div>
                    <div class="w-[95%] flex justify-center my-4">
                        {/* leyendo el estado del DOM se muestra el componente seleccionado */}
                        {categories[currentComponent]}
                    </div>
                </div>

            </main>

        </>
    )
}

export default Productos