import React, { useEffect, useState } from 'react'
import { CardProductos } from "../cards";
import { ProductModal } from "../productModal";
import AOS from "aos"
import 'aos/dist/aos.css'

function Masa() {

    useEffect(() => {
        AOS.init()
    }, [])

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)

    const handleOpenModal = (producto) => {
        setIsModalVisible(true)
        setSelectedProduct(producto)
    }

    const handleCloseModal = () => {
        setIsModalVisible(false)
        setSelectedProduct(null);
    }

    const data = [
        {
            id: 1,
            nombre: "Báscula cgolden wall",
            foto: "../assets/Aparatos/Masa/bascula-cgolden-wall.jpg"
        },
        {
            id: 2,
            nombre: "Cela de Carga-Mettler toleo MT1241",
            foto: "../assets/Aparatos/Masa/Cela-de-Carga-Mettler-toleo-MT1241.jpg"
        },
        {
            id: 3, 
            nombre: "Cela de Carga Rihn",
            foto: "../assets/Aparatos/Masa/Cela-de-Carga-Rihn.jpg"
        },
        {
            id: 4,
            nombre: "Teclado para Bascula Dibatec Lumina ss",
            foto: "../assets/Aparatos/Masa/Teclado-para-Bascula-Dibatec-Lumina-ss.jpeg"
        },
        {
            id: 5,
            nombre: "Balanza Analítica",
            foto: "../assets/Aparatos/Masa/Balanza-analitica.jpg"
        },
        {
            id: 6,
            nombre: "Balanza Digital",
            foto: "../assets/Aparatos/Masa/digital_balanza.jpg"
        },
    ]

    const productos = data.map(producto => {
        return (
            <>
                <CardProductos key={producto.id} producto={producto} onOpen={handleOpenModal} />
            </>
        )
    })

    return (
        <>
            <div class="container w-[95%] mx-auto p-4 flex justify-center my-4">
                <div data-aos="fade-up-left" class="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {productos}
                </div>
                {selectedProduct && (
                    <ProductModal isVisible={isModalVisible} onClose={handleCloseModal} producto={selectedProduct} />
                )}
            </div>
        </>
    )
}

export default Masa